<template>
	<div class="flex h-screen w-screen items-center justify-center overflow-hidden bg-login bg-cover">
		<Toolbar @clickRight="$router.push('/home')" variant="transparent">
			<template v-slot:start>
				<div class="flex w-24 justify-center rounded-xl bg-white p-2">
					<img class="mr-1" :src="require(`../assets/flags/${locale}.svg`)" alt="" />
					<div class="relative inline-block w-full">
						<select class="appearance-none bg-transparent pr-4 text-right outline-none" v-model="locale" v-on:change="setLang($event.target.value)">
							<option v-for="(item, index) in optionsLang" :key="index" :value="item.value">{{ item.text }}</option>
						</select>
						<div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
							<svg class="h-4 w-4 fill-current" viewBox="0 0 20 20">
								<path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path>
							</svg>
						</div>
					</div>
				</div>
			</template>
			<template v-slot:end>
				<p class="text-lg">{{ $t('launch.skip') }}</p>
			</template>
		</Toolbar>
		<div class="flex w-full flex-col items-center gap-5 p-5">
			<p class="text-3xl text-primary">MyJetmall</p>
			<Button @click="$router.push('/signin')">{{ $t('launch.signin') }}</Button>
			<Button @click="$router.push('/register')" variant="white">{{ $t('launch.register') }}</Button>

			<!-- <button @click="appleSignIn" type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="mb-1 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-2.5 font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg" style="background-color: #000000">
				<div class="flex items-center justify-between">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 h-6 mr-2">
						<path
							fill="currentColor"
							xmlns="http://www.w3.org/2000/svg"
							d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z"
						/>
					</svg>
					<p class="text-base">{{ $t('launch.appleSignIn') }}</p>
				</div>
			</button>

			<button @click="facebookSignIn" type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="mb-1 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-2.5 font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg" style="background-color: #1877f2">
				<div class="flex items-center justify-between">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-5 h-5 mr-2">
						<path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
					</svg>
					<p class="text-base">{{ $t('launch.facebookSignIn') }}</p>
				</div>
			</button>

			<button @click="googleSignIn" type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" class="mb-1 flex w-full items-center justify-center gap-2 rounded-lg px-6 py-2.5 font-medium leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg" style="background-color: #ea4335">
				<div class="flex items-center justify-between">
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" class="w-5 h-5 mr-2">
						<path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
					</svg>
					<p class="text-base">{{ $t('launch.googleSignIn') }}</p>
				</div>
			</button> -->
		</div>
	</div>
	<!-- Footer -->
	<div class="fixed bottom-0 w-full bg-white bg-opacity-40 p-2 text-center text-secondary pb-safe">
		<p class="mb-2 text-xs">Powered by <span class="text-blue-500">NubeTech </span>v {{ version }}</p>
	</div>

	<Toast v-if="error" @close="error = null">{{ error.message }}</Toast>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useCustomerStore } from '@/store/customer'
import { version } from '../../package.json'
import { useSharedStore } from '@/store/shared'

export default {
	setup() {
		const router = useRouter()
		const store = useStore()

		const { locale } = useI18n()

		const { setLang } = useSharedStore()

		const optionsLang = ref([
			{ text: '繁體', value: 'TC' },
			{ text: '簡体', value: 'SC' },
			{ text: 'ENG', value: 'EN' },
		])
		const { signInWithApple, signInWithFacebook, signInWithGoogle } = useCustomerStore()
		const error = ref(null)

		async function appleSignIn() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await signInWithApple()
				router.push('/account')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error('appleLogin', e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function facebookSignIn() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await signInWithFacebook()
				router.push('/account')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error('appleLogin', e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		async function googleSignIn() {
			try {
				store.commit('setLoadingState', { loadingState: true })
				error.value = null
				await signInWithGoogle()
				router.push('/account')
				store.commit('setLoadingState', { loadingState: false })
			} catch (e) {
				console.error('appleLogin', e)
				error.value = e
				store.commit('setLoadingState', { loadingState: false })
				setTimeout(() => {
					error.value = null
				}, 10000)
			}
		}

		return {
			error,
			setLang,
			locale,
			version,
			optionsLang,
			appleSignIn,
			facebookSignIn,
			googleSignIn,
		}
	},
}
</script>
